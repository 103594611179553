<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="550"
                  :options="optionsBarRejectedHealthUnits"
                  :series.sync="seriesBarRejectedHealthUnits"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Motivos de recusa de pagamento AIH</h4>
            <p class="category">
              Rejeitados de,
              <span class="text-success"
                >{{ dataSet.year }} {{ region.name }}</span
              >
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5">
            <md-table
              :value="table.health_unit"
              md-sort="name"
              md-sort-order="asc"
              md-card
              class="paginated-table table-striped table-hover"
              @md-selected="onSelect"
              md-fixed-header
            >
              <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
                :class="getClass(item)"
                md-selectable="single"
              >
                <md-table-cell md-label="CNES" md-sort-by="code">{{
                  item.code
                }}</md-table-cell>
                <md-table-cell md-label="Nome" md-sort-by="alias_company_name"
                  >{{ item.alias_company_name }}
                </md-table-cell>
                <md-table-cell md-label="Quant" md-sort-by="count_co_erro"
                  >{{ item.count_co_erro }}
                </md-table-cell>
                <md-table-cell md-label="Valor" md-sort-by="sum_val_tot"
                  >{{
                    Number(item.sum_val_tot).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-header>
          <md-card-content>
            <div class="">
              <p class="card-category">
                Mostrar {{ fromHealthUnit + 1 }} até {{ toHealthUnit }} de
                {{ total.health_unit }} entries
              </p>
            </div>
            <pagination
              class="pagination-no-border"
              v-model="pagination.health_unit.currentPage"
              :per-page="pagination.health_unit.perPage"
              :total="total.health_unit"
              @input="setPageHealthUnit"
            />
          </md-card-content>

          <md-card-actions md-alignment="space-between"> </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-xsmall-size-100 md-size-100"
        v-show="table.error.length > 0"
      >
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5">
            <md-table
              :value="table.error"
              md-sort="name"
              md-sort-order="asc"
              md-card
              class="paginated-table table-striped table-hover"
              @md-selected="onSelect"
              md-fixed-header
            >
              <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
                :class="getClass(item)"
                md-selectable="single"
              >
                <md-table-cell md-label="AIH" md-sort-by="n_aih">{{
                  item.n_aih
                }}</md-table-cell>
                <md-table-cell
                  md-label="Mês de competência"
                  md-sort-by="mes_cmpt"
                  >{{ item.mes_cmpt }}
                </md-table-cell>
                <md-table-cell md-label="Código do erro" md-sort-by="co_erro"
                  >{{ item.co_erro }}
                </md-table-cell>
                <md-table-cell md-label="Descrição" md-sort-by="ds_mot_err"
                  >{{ item.ds_mot_err }}
                </md-table-cell>
                <md-table-cell md-label="Valor" md-sort-by="val_tot"
                  >{{
                    Number(item.val_tot).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }}
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-header>
          <md-card-content>
            <div class="">
              <p class="card-category">
                Mostrar {{ fromError + 1 }} até {{ toError }} de
                {{ total.error }} entries
              </p>
            </div>
            <pagination
              class="pagination-no-border"
              v-model="pagination.error.currentPage"
              :per-page="pagination.error.perPage"
              :total="total.error"
              @input="setPageError"
            />
          </md-card-content>

          <md-card-actions md-alignment="space-between"> </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import { Pagination } from "@/components";
export default {
  components: {
    apexchart: VueApexCharts,
    Pagination
  },
  data() {
    return {
      region: { name: "" },
      health_unit_select: null,
      source: this.$route.params.source,
      type: this.$route.params.type,
      id: this.$route.params.id,
      location_type: this.$route.params.location_type
        ? this.$route.params.location_type
        : null,
      location_id: this.$route.params.location_id
        ? this.$route.params.location_id
        : null,
      filter_cids: this.$store.state.aih.filter_cids,
      filter_chapters_cids: this.$store.state.aih.filter_chapters_cids,
      filter_groups_cids: this.$store.state.aih.filter_groups_cids,
      dataSet: { year: null, alias: null },
      seriesBarRejectedHealthUnits: [],
      optionsBarRejectedHealthUnits: {
        chart: {
          type: "bar",
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "80%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
              orientation: "horizontal"
            }
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"],
            fontSize: "10px"
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        title: {
          text: "Classificação de AIH Rejeitadas",
          align: "center",
          floating: true
        },
        subtitle: {
          text: "Ordenado pela incidência do código de erro",
          align: "center"
        },
        tooltip: {
          theme: "light",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        }
      },
      table: {
        health_unit: [],
        error: []
      },
      total: {
        health_unit: 0,
        error: 0
      },
      query: null,

      sortation: {
        health_unit: {
          field: "val_tot",
          order: "asc"
        },
        error: {
          field: "val_tot",
          order: "asc"
        }
      },

      pagination: {
        health_unit: {
          perPage: 15,
          currentPage: 1,
          perPageOptions: [10, 15, 20, 25, 50]
        },
        error: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [10, 15, 20, 25, 50]
        }
      },

      apiUrl: `${process.env.VUE_APP_API_BASE_URL}/`
    };
  },
  async mounted() {
    this.getDataSet();
    this.welcomeMessage();
    this.getTableRejectedHealthUnits();
    this.getSerieRejectedHealthUnits();
    if (this.location_type != null) {
      this.getRegion();
    }
  },
  computed: {
    sortHealthUnit() {
      if (this.sortation.health_unit.order === "desc") {
        return `-${this.sortation.health_unit.field}`;
      }

      return this.sortation.health_unit.field;
    },

    fromHealthUnit() {
      return (
        this.pagination.health_unit.perPage *
        (this.pagination.health_unit.currentPage - 1)
      );
    },

    toHealthUnit() {
      let highBound = this.fromHealthUnit + this.pagination.health_unit.perPage;
      if (this.total.health_unit < highBound) {
        highBound = this.total.health_unit;
      }
      return highBound;
    },
    sortError() {
      if (this.sortation.health_unit.order === "desc") {
        return `-${this.sortation.health_unit.field}`;
      }

      return this.sortation.health_unit.field;
    },

    fromError() {
      return (
        this.pagination.health_unit.perPage *
        (this.pagination.health_unit.currentPage - 1)
      );
    },

    toError() {
      let highBound = this.fromError + this.pagination.error.perPage;
      if (this.total.error < highBound) {
        highBound = this.total.error;
      }
      return highBound;
    }
  },
  methods: {
    async welcomeMessage(name = "") {
      await this.getDataSet();
      this.$emit("welcomeMessage", {
        title: `Dados de SIH do ano de ${this.dataSet.year} ${name}`
      });
    },
    async getDataSet() {
      let result = await Vue.$http.get(`v1/dataset/${this.id}/`);
      this.dataSet = result.data;
    },
    async getSerieRejectedHealthUnits() {
      let result;
      if (this.location_type == null) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sih/${this.id}/`,
          {
            params: {
              per: "cnes",
              operation: "count",
              rating: "co_erro",
              per_page: this.pagination.health_unit.perPage,
              page: this.pagination.health_unit.currentPage
            }
          }
        );
      } else {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sih/${this.id}/`,
          {
            params: {
              per: "cnes",
              operation: "count",
              rating: "co_erro",
              per_page: this.pagination.health_unit.perPage,
              page: this.pagination.health_unit.currentPage,
              location_type: this.location_type,
              location_id: this.location_id
            }
          }
        );
      }

      let data = [];
      while (this.optionsBarRejectedHealthUnits.xaxis.categories.length > 0) {
        this.optionsBarRejectedHealthUnits.xaxis.categories.pop();
      }
      result.data.data.forEach(async itemData => {
        this.optionsBarRejectedHealthUnits.xaxis.categories.push(
          itemData.alias_company_name
        );
        data.push(itemData.count);
      });
      while (this.seriesBarRejectedHealthUnits.length > 0) {
        this.seriesBarRejectedHealthUnits.pop();
      }
      this.seriesBarRejectedHealthUnits.push({
        name: "HealthUnits",
        data: data
      });
    },
    setPageHealthUnit(page) {
      this.pagination.health_unit.currentPage = page;
      this.getTableRejectedHealthUnits();
      this.getSerieRejectedHealthUnits();
    },
    setPageError(page) {
      this.pagination.error.currentPage = page;
      this.getTableErrorHealthUnit();
    },
    async getTableRejectedHealthUnits() {
      let result;
      if (this.location_type == null) {
        result = await Vue.$http.get(
          `v1/dataset/consume/table/datasus/sih/${this.id}/`,
          {
            params: {
              per: "cnes",
              ratings: ["co_erro", "val_tot"],
              operations: ["count", "sum"],
              columns: ["alias_company_name"],
              per_page: this.pagination.health_unit.perPage,
              page: this.pagination.health_unit.currentPage
            }
          }
        );
      } else {
        result = await Vue.$http.get(
          `v1/dataset/consume/table/datasus/sih/${this.id}/`,
          {
            params: {
              per: "cnes",
              ratings: ["co_erro", "val_tot"],
              operations: ["count", "sum"],
              columns: ["alias_company_name"],
              location_type: this.location_type,
              location_id: this.location_id,
              per_page: this.pagination.health_unit.perPage,
              page: this.pagination.health_unit.currentPage
            }
          }
        );
      }

      this.table.health_unit = result.data.data;
      this.total.health_unit = result.data.total;
    },
    async getRegion() {
      let result;
      if (this.location_type == "city") {
        result = await Vue.$http.get(`v1/city_show/${this.location_id}/`);
      } else if (this.location_type == "microregion") {
        result = await Vue.$http.get(
          `v1/microregion_sesapi_show/${this.location_id}/`
        );
      } else if (this.location_type == "mesoregion") {
        result = await Vue.$http.get(
          `v1/mesoregion_sesapi_show/${this.location_id}/`
        );
      }
      this.region = result.data;
      this.welcomeMessage(this.region.name);
    },
    getClass: () => {
      return "md-primary";
    },
    async onSelect(item) {
      this.health_unit_select = item.code;
      this.getTableErrorHealthUnit();
    },
    async getTableErrorHealthUnit() {
      let result;
      if (this.location_type == null) {
        result = await Vue.$http.get(
          `v1/dataset/consume/table/datasus/sih/${this.id}/`,
          {
            params: {
              per: "cnes",
              columns: [
                "n_aih",
                "mes_cmpt",
                "co_erro",
                "val_tot",
                "ds_mot_err"
              ],
              column_filter: ["cnes"],
              term_filter: [this.health_unit_select],
              per_page: this.pagination.error.perPage,
              page: this.pagination.error.currentPage
            }
          }
        );
      } else {
        result = await Vue.$http.get(
          `v1/dataset/consume/table/datasus/sih/${this.id}/`,
          {
            params: {
              per: "cnes",
              columns: [
                "n_aih",
                "mes_cmpt",
                "co_erro",
                "val_tot",
                "ds_mot_err"
              ],
              column_filter: ["cnes"],
              term_filter: [this.health_unit_select],
              location_type: this.location_type,
              location_id: this.location_id,
              per_page: this.pagination.error.perPage,
              page: this.pagination.error.currentPage
            }
          }
        );
      }

      this.table.error = result.data.data;
      this.total.error = result.data.total;
    }
  }
};
</script>

<style></style>
